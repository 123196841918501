<template>
  <div class="viewer-wrapper">
    <div class="viewer-wrapper_header">
      <div class="viewer-wrapper_header-line">
        <h1>Просмотр: IMG_20240901_155553.jpg</h1>
        <div class="tw-flex tw-justify-center">
          <div class="tw-flex tw-items-center tw-justify-between tw-gap-x-4">
            <button class="tw-p-1.5 tw-rounded-full tw-bg-[#0DB2B2]">
              <i-chevron-left class="tw-w-3 tw-h-3 tw-text-white max-md:tw-rotate-90" stroke-width="3" />
            </button>
            <zem-dropdown-list
              v-model="selectedZoom"
              :options="['10%', '25%', '50%', '85%']"
              class="tw-m-0 tw-w-[100px]"
              type="string"
            />
            <button class="tw-p-1.5 tw-rounded-full tw-bg-[#0DB2B2]">
              <i-chevron-right class="tw-w-3 tw-h-3 tw-text-white max-md:tw-rotate-90" stroke-width="3" />
            </button>
          </div>
        </div>
        <div>
          <zem-link>
            <img :src="require('@/assets/icons/basket.svg')" alt="" class="mr-1" />
            <span class="mobile-d-none">{{ $t('Delete') }}</span>
          </zem-link>
          <zem-link class="mt-0 zem-link-mobile">
            <download-icon class="mr-1" size="16" />
            <span>{{ $t('Download') }}</span>
          </zem-link>
          <zem-button @click="$emit('close')">
            <img :src="require('@/assets/icons/close-o.svg')" alt="" class="tw-size-4" />
            Закрыть
          </zem-button>
        </div>
      </div>
      <span class="viewer-wrapper_header-details">Разрешение: 1200 х 800 Размер: 1200 кб</span>
    </div>
    <div class="viewer-wrapper_image">
      <img alt="" src="@/assets/images/02e22756cec0f4dd96352a22ac58808e.jpeg" />
    </div>
  </div>
</template>

<script>
import ZemLink from '@/components/ui/ZemLink.vue'
import {DownloadIcon} from 'vue-feather-icons'
import ZemButton from '@/components/ui/ZemButton.vue'
import IChevronRight from '@/components/icons/iChevronRight.vue'
import IChevronLeft from '@/components/icons/iChevronLeft.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'

export default {
  components: {ZemDropdownList, IChevronLeft, IChevronRight, ZemButton, ZemLink, DownloadIcon},
  data() {
    return {
      selectedZoom: '100%',
    }
  },
}
</script>

<style lang="scss" scoped>
.viewer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &_header {
    width: 100%;
    padding: 30px 20px 8px 20px;
    background-color: #ffffff;

    &-line {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      h1 {
        font-size: 18px;
        font-weight: 700;
        color: #3e3e3e;
        width: 33.33%;
      }

      div {
        width: 33.33%;
      }

      div:first-child {
        display: flex;
        justify-content: center;
      }

      div:last-child {
        display: flex;
        justify-content: flex-end;
        gap: 14px;
      }
    }

    &-details {
      font-size: 10px;
      color: #9ba6b2;
    }
  }

  &_image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
