import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'
import {eventBus} from "@/main";

class ObjectsService {
  getObjects(list = false, isLoadNew = false) {
    if (!list && !isLoadNew) store.commit('other/onChangeLoadingItems', true)

    const {orderBy, sortedBy, search, paginationPage, filter, isArchive, elements} = store.state.objects

    let params = {
      include: 'region,highway,squareUnit,createdBy,updatedBy',
      searchJoin: 'and',
    }
    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy
    if (filter !== null) params['search'] = filter.main
    if (search !== '') params['search'] = `sku:${search}`
    if (list) {
      params['to'] = 'list'
    } else {
      params['page'] = paginationPage
    }

    if (isArchive) params['only-trashed'] = `1`

    return $api
      .get('objects', {
        params,
        headers: authHeader(),
      })
      .then(r => {
        if (!isLoadNew) {
          eventBus.$emit('update-table-settings', r.data.meta.custom.settings.value.data)
          store.commit('objects/getAllObjects', r.data)
        } else {
          store.commit('objects/getAllObjects', {data: [...elements, ...r.data.data], meta: r.data.meta})
        }
        if (!list) store.commit('other/onChangeLoadingItems', false)
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  getObjectsList(inputSearch) {
    let params = {
      to: 'list',
      'title-as': 'sku',
      limit: '*',
    }
    if (inputSearch) params['search'] = `sku:${inputSearch}`
    return $api
      .get('objects', {
        params,
        headers: authHeader(),
      })
      .then(r => {
        return r.data
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  async getObject(id) {
    return await $api
      .get('objects/' + id, {
        params: {
          include: 'region,highway,orders,orders.pipeline,orders.status,squareUnit,createdBy,updatedBy',
        },
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  async createObject(data) {
    return await $api.post('objects', data, {
      headers: authHeader(),
    })
  }

  updateObject(id, data) {
    return $api.patch('objects/' + id, data, {
      headers: authHeader(),
    })
  }

  deleteItems(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api.delete('objects', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.objects.selectedItemsForDelete,
      },
    })
  }

  archiveItems() {
    return $api.post(
      'restore/objects',
      {
        ids: store.state.objects.selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }

  mergeItems(params) {
    return $api.post(
      'objects/merge',
      {},
      {
        params,
        headers: authHeader(),
      }
    )
  }
}

export default new ObjectsService()
